import React, {useState} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from './pages/Contact';
import Contextual from './pages/Contextual';
import GlobalAdInventory from './pages/GlobalAdInventory';
import Nativeadvr from './pages/Nativeadvr';
import ProspectingAds from './pages/ProspectingAds';
import Retargeting from './pages/Retargeting';
import Roastargeting from './pages/Roastargeting';
import WhoWeAre from './pages/WhoWeAre';
import HowWeDo from './pages/HowWeDo';
import ProgramAds from './pages/ProgramAds';
import LoadingBar from 'react-top-loading-bar';

function App() {
  const [progress,setProgress] = useState(0);
  return (
    <>
      <LoadingBar color='#A3CE55' progress={progress} onLoaderFinished={() => setProgress(0)}/>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Home setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/home" element={<Home setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/who-we-are" element={<WhoWeAre setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/how-we-do" element={<HowWeDo setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/contact-us" element={<Contact setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/contextual" element={<Contextual setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/globaladinventory" element={<GlobalAdInventory setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/nativeadvr" element={<Nativeadvr setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/prospecting-ads" element={<ProspectingAds setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/retargeting" element={<Retargeting setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/roastargeting" element={<Roastargeting setProgress={(data)=>setProgress(data)}/>} />
          <Route excect path="/programmatic-ads" element={<ProgramAds setProgress={(data)=>setProgress(data)}/>} />
      </Routes>
    </BrowserRouter></>
  );
}

export default App;
