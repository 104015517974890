import React from "react";
import { Link } from "react-router-dom";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";

export default function Footer(props) {
    const {noFooterForm} = props
  return (
    <footer className="footer">
      <div className="footer-bg-img"></div>
      <div className="footer-bg-img tringle"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 pe-md-5">
            {!noFooterForm && <div className="conectwith-us">
              <h2 className="footer-heading">
                <span className="text-primary">Connect</span> With Us
              </h2>
              <form action="">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="name" className="sr-only"></label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Name*"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="email" className="sr-only"></label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Email*"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="number" className="sr-only"></label>
                      <input
                        type="number"
                        className="form-control"
                        id="number"
                        placeholder="Phone Number*"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label for="text01" className="sr-only"></label>
                      <select
                        className="form-control"
                        id="text01"
                        placeholder="Select*"
                      >
                        <option value="">For*</option>
                        <option value="Advertiser">Advertiser</option>
                        <option value="Publisher">Publisher</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label for="message" className="sr-only d-none">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="6"
                    cols="50"
                    className="form-control"
                  >
                    Message*
                  </textarea>
                </div>

                <div className="btn-wrap">
                  <button className="btn-primary btn" type="button">
                    Call Now
                  </button>
                </div>
              </form>
            </div>}
          </div>
          <div className="col-md-6">
            <div className="footer-info">
              <ul className="list-unstyled connet-links">
                <li className="item">
                  <span className="label mb-2 text-primary">Office:</span>
                  1707, 17th Floor, Indigo Icon, Cluster F , Jumeirah Lake Towers, Dubai
                </li>
                <li className="item">
                  <span className="label mb-2 text-primary">Phone:</span>
                  <a href="tel:+97145298257" target="_blank" title="Call">
                  +971 4 529 8257
                  </a>
                  <a href="tel:+971502232118" target="_blank" title="Call">
                  +971 50 223 2118
                  </a>
                </li>
                <li className="item">
                  <span className="label mb-2 text-primary">Email:</span>
                  <a
                    href="mailto:payal@futureflixmedia.com"
                    target="_blank"
                    title="Mail"
                  >
                    payal@futureflixmedia.com
                  </a>
                </li>
              </ul>
            </div>

            <ul className="list-unstyled p-0  d-flex align-items-center justify-content-center justify-content-lg-end mb-4 footer-social">
              <li className="item">
                <a href="https://www.linkedin.com/company/future-flix-media/" target="_blank" title="Linkdin">
                  <span className="icon socialicon">
                    <BsLinkedin></BsLinkedin>
                  </span>
                </a>
              </li>
              <li className="item ">
                <a href="https://www.instagram.com/futureflixmedia/" target="_blank" title="Instagram">
                  <span className="icon socialicon">
                    <BsInstagram></BsInstagram>
                  </span>
                </a>
              </li>
              <li className="item">
                <a href="https://www.facebook.com/futureflixmedia" target="_blank" title="Facebook">
                  <span className="icon socialicon">
                    <BsFacebook></BsFacebook>
                  </span>
                </a>
              </li>
            </ul>
            <ul className="list-unstyled p-0 footer-links d-flex align-items-center justify-content-center justify-content-lg-end mb-2">
              <li className="item">
                <a
                  href="/who-we-are"
                  className="f-links"
                  target="_self"
                  title="About US"
                >
                  About Us
                </a>
              </li>
              <li className="item">
                <a
                  href="#"
                  className="f-links"
                  target="_self"
                  title="Privacy Policy "
                >
                  Privacy Policy
                </a>
              </li>
              <li className="item">
                <a href="#" className="f-links" target="_self" title="Declimer">
                Disclaimer
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
