import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Link, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Home(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Home </title>
      </Helmet>
      <main className="main home-page">
        <section class="section home-banner-section">
          <div class="container">
            <div class="row">
              <div class="col-md-5 order-2 order-lg-1" data-aos="fade-right" data-aos-offset="10">
                <h1 class="heading">
                  Future <br />
                  <span>Tech</span> <br />
                  Advertising
                </h1>
                <div class="sub-heading">where content meets context</div>
                <p>
                  {" "}
                  Cutting edge technology to revolutionize advertising with
                  AI-powered context targeting, delivering superior results and
                  driving brand success to new heights.
                </p>
                <div class="btn-wrap">
                  <a href="/contextual" class="btn btn-primary" replace={true}>
                    Know More
                  </a>
                </div>
              </div>
              <div
                class="col-md-7 order-1 order-lg-2 mb-lg-0 mb-3"
                data-aos="fade-left"
              >
                <div class="banner-image">
                  <img
                    src="./images/home-banner.png"
                    alt="Home-banner"
                    width="679"
                    height="685"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section home-annouce-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6" data-aos="fade-up">
                <div className="img-wrap">
                  <img
                    src="/images/home-media.png"
                    alt="announced"
                    width="570"
                    height="537"
                  />
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-left">
                <div className="box-borderd">
                  <div className="title">
                    Revolutionizing{" "}
                    <span className="text-primary">Ad Experiences</span>,
                    FutureFlix Media.{" "}
                  </div>
                  <p>
                    We blend technology and creativity to captivate audiences
                    with dynamic campaigns. Our ground breaking AI technology, <span style={{color:"#A3CE55"}}>X
                    rae </span> unlocks the power of micro-level analysis, delivering
                    ads that resonate with your audience and drive conversions
                    like never before.
                  </p>
                  <a href="https://contextads.futureflixmedia.com/" target="_blank" className="btn btn-reverce">
                  Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="service-section">
          <div className="curve-img">
            <img src="images/curve.svg" alt="curve" height={229} />
          </div>
          <div className="container">
            <div className="title">Our Services</div>
            <div className="three-col-view">
              <div className="item lightgreen">
                <div className="icon">
                  <img
                    src="./images/contextual-advertising.svg"
                    alt="contextual-advertising"
                    width="132"
                    height="105"
                  />
                </div>
                <div className="sub-title">Contextual Advertising</div>
                <p className="desc">
                  Real-Time Targeting
                  <br />
                  Contextual Relevance
                  <br />
                  Brand Safety
                  <br />
                  Transparent Reporting
                </p>
              </div>
              <div className="item">
                <div className="icon">
                  <img
                    src="./images/native.svg"
                    alt="Native Advertising"
                    width="132"
                    height="105"
                  />
                </div>
                <div className="sub-title">Native Advertising</div>
                <p className="desc">
                  Premium Publishers
                  <br />
                  Smart Targeting
                  <br />
                  Non-intrusive
                  <br />
                  Mindset Targeting
                </p>
              </div>
              <div className="item lightgreen">
                <div className="icon">
                  <img
                    src="./images/prog-ad.svg"
                    alt="Programmatic Advertising"
                    width="132"
                    height="105"
                  />
                </div>
                <div className="sub-title">Programmatic Advertising</div>
                <p className="desc">
                  Global audience
                  <br />
                  User friendly interface
                  <br />
                  Cost effective
                  <br />
                  Real-Time bidding
                </p>
              </div>
              <div className="item">
                <div className="icon">
                  <img
                    src="./images/Retargeting-Ads.svg"
                    alt="Retargeting Ads"
                    width="132"
                    height="105"
                  />
                </div>
                <div className="sub-title">Retargeting Ads</div>
                <p className="desc">
                  Brand Recall
                  <br />
                  Wider Reach
                  <br />
                  Gain insights
                  <br />
                  Improve ROI
                </p>
              </div>
              <div className="item lightgreen">
                <div className="icon">
                  <img
                    src="./images/inf-targeting.svg"
                    alt="Influencer Marketing"
                    width="132"
                    height="105"
                  />
                </div>
                <div className="sub-title">Influencer Marketing</div>
                <p className="desc">
                  Influencer Onboard
                  <br />
                  Content Creation
                  <br />
                  Relationship Management
                  <br />
                  Brand Credibility
                </p>
              </div>
              <div className="item">
                <div className="icon">
                  <img
                    src="./images/perfo-marketing.svg"
                    alt="Influencer Marketing"
                    width="132"
                    height="105"
                  />
                </div>
                <div className="sub-title">Performance Marketing</div>
                <p className="desc">
                  Trackable
                  <br />
                  Website visitors
                  <br />
                  Conversions
                  <br />
                  Premium Support
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="section funnel-section">
          <div class="bg-img image-left">
            <img
              src="./images/funnel-bg.png"
              alt="funnel-bg"
              width="200"
              height="644"
            />
          </div>
          <div class="bg-img image-right">
            <img
              src="./images/bg-spark.png"
              alt="bg-spark"
              width="200"
              height="644"
            />
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-6 order-2 order-lg-1" data-aos="fade-right">
                <div class="sub-heading">Breaking The Advertising Barriers With <span style={{color:"#a3ce55"}}>Future Flix Media</span></div>
                <p>
                  {" "}
                  Dive into a realm where creativity knows no bounds and innovation defies convention. Discover how we're transcending traditional advertising norms, revolutionizing campaigns, and sparking connections that resonate.
                </p>
                <p>
                Join us in breaking the advertising barriers and charting a new course for captivating communication.
                </p>
                <div class="btn-wrap">
                  <a href="/contact-us" class="btn btn-primary" replace={true}>
                  Contact Now
                  </a>
                </div>
              </div>
              <div
                class="col-md-6 order-1 order-lg-2 mb-lg-0 mb-3"
                data-aos="fade-left"
              >
                <div class="banner-image">
                  <img
                    src="./images/app-mockup.png"
                    alt="Home-banner"
                    width="508"
                    height="416"
                    style={{margin:"auto", display: "block"}}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section ourwork-section">
          <div class="container">
            <div class="text-center">
              <h2 class="heading-center">
                Our <span class="text-primary">Work</span>
              </h2>
            </div>
            <div class="three-col-view">
              <div class="item">
                <img
                  src="/images/gallery01.png"
                  alt="gallery01"
                  width="432"
                  height="288"
                />
              </div>
              <div class="item">
                <img
                  src="/images/gallery02.png"
                  alt="gallery02"
                  width="432"
                  height="288"
                />
              </div>
              <div class="item">
                <img
                  src="/images/gallery03.png"
                  alt="gallery03"
                  width="432"
                  height="288"
                />
              </div>
              <div class="item">
                <img
                  src="/images/gallery04.png"
                  alt="gallery05"
                  width="432"
                  height="288"
                />
              </div>
              <div class="item">
                <img
                  src="/images/gallery05.png"
                  alt="gallery04"
                  width="432"
                  height="288"
                />
              </div>
              <div class="item">
                <img
                  src="/images/gallery06.png"
                  alt="gallery01"
                  width="432"
                  height="288"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
