import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Breadcumb from "../components/Breadcumb";

export default function WhoWeAre(props) {
  useEffect(() => {
    props.setProgress(100);
  }, []);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Future Flix | Who We Are </title>
      </Helmet>
      <main class="main retargeting-page">
        <Breadcumb title="Who We Are" desc="We love being pioneers" />

        <section class="section content-image-section sec-space">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 order-lg-1 order-2" data-aos="fade-right" data-aos-offset="10">
                <div class="heading-center">
                  Your Vision, Our Commitment: The{" "}
                  <span class="text-primary">Future Flix</span> Way
                </div>
                <p>
                  We are a team of creative enthusiasts, fueled by AI powered
                  innovation and armed with expertise in digital marketing.
                  We're on a mission to deliver outcomes that truly resonate.
                  Our playground is where imagination meets technology, crafting
                  compelling digital marketing campaigns that empower businesses
                  of every magnitude. Drawing inspiration from our name, Future
                  Flix, we conceptualize futuristic ad streaming with contextual
                  relevance that empower businesses of every magnitude. Embark
                  on a journey of futuristic creativity with us!
                </p>

                <a href="#values" class="btn btn-primary  mt-3 mb-3">
                  Learn More
                </a>
              </div>
              <div class="col-lg-6 text-lg-end mb-lg-0 mb-4 order-lg-2 order-1" data-aos="fade-left">
                <div class="img">
                  <img
                    src="./images/office-vector.png"
                    alt="office-vector"
                    width="609"
                    height="520"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="section content-image-section sec-space sec-bg"
          id="values"
        >
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-6 text-lg-end mb-lg-0 mb-4" data-aos="fade-right">
                <div class="img pe-lg-4">
                  <img
                    src="./images/megnet-vector.png"
                    alt="megnet-vector"
                    width="635"
                    height="398"
                  />
                </div>
              </div>
              <div class="col-lg-6" data-aos="fade-left">
                <div class="heading-center">
                  Our <span class="text-primary">Values</span>
                </div>
                <p>
                  At Future Flix Media, our work culture revolves around our
                  core values. We cherish collaboration, fuel creativity, and
                  prioritize client contentment. Our pledge is to offer bespoke
                  solutions, finely tuned to meet the distinct needs of every
                  client.
                </p>

                <p>
                  <b> Client Dedication:</b> We hold our clients' trust in high
                  regard, and our relentless determination showcases our
                  unwavering commitment.
                </p>
                <p>
                  <b>Unrivaled Excellence:</b> We serve innovation on a silver
                  platter, leaving outdated solutions in the dust.
                </p>
                <p>
                  <b>Innovation:</b> In a world teeming with challenges, we
                  believe in rewriting the rules. It's not just about thinking
                  outside the box; it's about building a whole new box.
                </p>

                <a href="/contact-us" class="btn btn-primary  mt-5 mb-3">
                  Contact Us Now!
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
