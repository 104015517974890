import React, {useEffect} from 'react';
import Footer from './Footer';
import Header from './Header';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Layout({children, ...props}) {
  const {noFooterForm} = props;
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])
    return (
      <>
        <Header />
        {children}
        <Footer noFooterForm={noFooterForm} />
      </>
    );
  }
